import React from "react"
import { graphql } from 'gatsby'

import Banner from '../components/banner'
import Layout from '../components/layout'
import SEO from "../components/seo"
import Hero from '../components/hero'
import Listing from '../components/listing'
import ScrollToTop from '../components/scroll'

import { prettyCategory } from '../utils/helpers'

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'

const singleCanton = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing ).sort((a, b) => {
        // Filtre pour que les "guests" soient toujours en dernier.
        const listingA = a.data.guest === true ? 1 : 0;
        const listingB = b.data.guest === true ? 1 : 0;

        return listingA - listingB; // Moves "true" values to the end
    })
    
    const category = props.pageContext.category
    const canton = props.pageContext.cantonName
    var resultsNumber = listings.filter( listing => listing !== null ).length

    // console.log("LISTINGS", listings)

    // For adverts
    const img_advert1 = props.data.prismicCategory.data.banner_1.fluid
    const img_advert2 = props.data.prismicCategory.data.banner_2.fluid
    const url_advert1 = props.data.prismicCategory.data.link_banner_1.url
    const url_advert2 = props.data.prismicCategory.data.link_banner_2.url

    const banners = {
        'banner1': {
            'img': img_advert1,
            'url': url_advert1,
        },
        'banner2': {
            'img': img_advert2,
            'url': url_advert2,
        }
    }

    return (
        <Layout
            ads={url_advert1 ? banners : null}
        >
            <SEO title={canton} description={`Tous nos établissements dans le canton ${canton}`} />

            <Container>
                <Hero 
                    resultsNumber={resultsNumber}
                    category={category}
                    canton={canton}
                />

                <div
                    sx={{
                        display:  "flex",
                        flexDirection: "column",
                        gap: 3,
                    }}
                >            
                    {listings.map( (listing, index) => {
                        return (
                            <React.Fragment key={listing.id}>
                                <Listing 
                                    uid={listing.uid}
                                    data={listing.data}
                                    prettyCategory={prettyCategory(category)}
                                />
                                {index > 3 && (index === 4 || index === 14 || index === 34) && <Banner position={index + 2} />}
                            </React.Fragment>
                        )
                    })}
                </div>
            </Container>

            <ScrollToTop showAfterHeight={800} />
        </Layout>
    )
}

export default singleCanton

export const singleCantonQuery = graphql`
    query singleCantonListings($category: String!, $canton: String!) {
        allPrismicEntreprise(
            filter: {data: {
                categories: {elemMatch: {category: {uid: {eq: $category}}}}, 
                locations: {elemMatch: {zones: {uid: {eq: $canton}}}},
                draft: {ne: true}
            }},
            sort: {order: DESC, fields: [last_publication_date, data___verified]}
            ) {
            edges {
                node {
                    uid
                    ...ListingFragment
                }
            }
        }
        prismicCategory(uid: {eq: $category}) {
            uid
            data {
                hide_filters
                hero_image {
                    fluid {
                        ...GatsbyPrismicImageFluid
                    }
                }
                seo_text {
                    html
                }
                banner_1 {
                    fluid {
                        ...GatsbyPrismicImageFluid
                    }
                }
                banner_2 {
                    fluid {
                        ...GatsbyPrismicImageFluid
                    }
                }
                link_banner_1 {
                    url
                }
                link_banner_2 {
                    url
                }
            }
        }
    }
`