/** @jsx jsx */
import { jsx } from 'theme-ui'

const Tags = (props) => {
    const {
        tags,
        color,
    } = props

    function toTitleCase(str) {
        return str
            .split(' ')
            .map(word => {
                // Leave the word as is if it's all caps (more than one character)
                return word === word.toUpperCase() && word.length > 1
                    ? word
                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' ');
    }

    return (
        <div sx={{
            my: 1,
        }}>
            {tags.map( (tag, index) => (
                <span
                    sx={{
                        display: 'inline-block',
                        px: 3, py: 1, mr: 1, mb: 1, borderRadius: 1,
                        fontSize: 0, fontWeight: 'bold', color: 'background', bg: color ? color : 'secondary',
                    }}
                    key={tag + '_' + index}
                >
                    #{toTitleCase(tag)}
                </span>
            ))}
        </div>
    )
}

export default Tags