// import React from 'react'

/** @jsx jsx */
import { jsx } from 'theme-ui'

import { FaFacebook, FaTwitterSquare, FaInstagram, FaLinkedin, FaYoutube, FaVimeoV, FaPinterestSquare, FaTiktok } from 'react-icons/fa'


const SocialLinks = (props) => {
    const { 
        facebook,
        instagram,
        twitter,
        linkedin,
        youtube,
        vimeo,
        tiktok,
        pinterest,
    } = props.data

    // console.log("SOCIAL", tiktok)

    const ExtLink = ({url, children, title, destination}) => {
        return (
            <a href={url} target="_blank" rel="noreferrer noopener nofollow"
                title={title}
                className="social-link"
                data-uid={props.uid}
                data-social-network={destination}
                sx={{
                    color: 'grey',
                    '&:hover': {
                        color: 'primary',
                    }
                }}
            >
                {children}
            </a>
        )
    }

    const social_links = [
        {id: 'facebook', url: facebook.url, icon: <FaFacebook />},
        {id: 'instagram', url: instagram.url, icon: <FaInstagram />},
        {id: 'twitter', url: twitter.url, icon: <FaTwitterSquare />},
        {id: 'linkedin', url: linkedin.url, icon: <FaLinkedin />},
        {id: 'youtube', url: youtube.url, icon: <FaYoutube />},
        {id: 'vimeo', url: vimeo.url, icon: <FaVimeoV />},
        {id: 'tiktok', url: tiktok.url, icon: <FaTiktok />},
        {id: 'pinterest', url: pinterest.url, icon: <FaPinterestSquare />},
    ]

    return (
        <ul sx={{
                display: 'flex', 
                justifyContent: 'flex-start',
                listStyleType: "none", 
                textAlign: 'left',
                m: 0,
                ml: ['-15px', '-15px', '0'],
                pl: ['0'],
                gap: 2,
            }}
        >
            {social_links.filter( item => item.url ).map( link => (
                <li key={link.id} 
                    sx={{
                        fontSize: 3,
                    }}
                >
                    <ExtLink url={link.url} 
                        title={`Aller sur ${link.id}`}
                        destination={link.id}
                    >
                        {link.icon}
                    </ExtLink>
                </li>
            ))}
        </ul>
    )
}

export default SocialLinks