// import React from 'react'
import { FaExternalLinkSquareAlt, FaPhone, FaInfoCircle, FaBookmark } from 'react-icons/fa'
import { MdPlace, MdEmail, MdVerifiedUser } from 'react-icons/md'
import { HiSpeakerphone } from 'react-icons/hi'

// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"
import { Link } from "gatsby"

/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui'

import SocialLinks from './socialLinks'
import Button from './button'
import Tags from './tags'

const Listing = (props) => {
    // console.log("LISTING PROPS", props)

    const uid = props.uid
    const tags = props.tags
    const name = props.data.record.text
    const pitch = props.data.pitch.text
    // const url = props.data.website.url
    // const telephone = props.data.phone_number ? props.data.phone_number.replace(/\s+/g, "") : null
    // const email = props.data.contact_email
    const logo = props.data.logo.fixed
    const cover = props.data.cover.fluid
    const verified = props.data.verified
    const enhancedprofile = props.data.enhancedprofile
    const rank = props.data.rank
    const guest =  props.data.guest
    // const category = prettyCategory(props.data.categories[0].category.uid)
    const locations = props.data.locations.filter(location => location.name !== "").map( location => location.name ).join(" - ")
    const prettyCategory = props.prettyCategory

    const addLinks = props.addLinks

    // console.log("locations", locations)

    const ConditionalLink = ({ children, ...props }) => {
        if (guest) {
            return (
                <div {...props}>
                    {children}
                </div>
            )
        } else {
            return (
                <Link 
                    to={`/${uid}`}
                    {...props}
                >
                        {children}
                </Link>
            )
        }
    }

    return (
        <div id={`listing-${uid}`}
            sx={{
                borderRadius: 1,
                boxShadow: 0,
                bg: 'background',
                overflow: 'hidden',
                position: 'relative',
                border: (enhancedprofile || rank) ? '3px solid limegreen' : 'none',
                ...(guest 
                    ? {
                        opacity: '66%',
                    } : {                        
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.02)',
                        }
                    })
            }}
        >
            {enhancedprofile && cover && <Img fluid={cover} />}

            {(enhancedprofile || rank) && 
                <div sx={{
                    position: 'absolute',
                    top: 0, left: 0,
                    fontSize: 0,
                    color: 'white', bg: 'limegreen',
                    py: 1, px: 2,
                    display: 'inline-block',
                    borderBottomRightRadius: 0,
                    // outline: '4px solid rgba(50, 205, 50, 0.33)',
                }}>
                    {enhancedprofile && !rank && <span><FaBookmark sx={{mb: '-2px'}}/> Membre PRO</span>}
                    {rank && <span><HiSpeakerphone sx={{mb: '-2px'}}/> BOOST</span>}
                </div>
            }

            <div sx={{
                pt: 4,
                px: 4,
                pb: 0,
            }}>
                <Flex
                    sx={{
                        justifyContent: ['flex-start', 'flex-start', 'space-between'],
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                    }}
                >
                    {logo &&
                        <Box>
                            <ConditionalLink 
                                sx={{
                                    textDecoration: 'none',
                                    color: 'text',
                                }}
                            >
                                <Img 
                                    fixed={logo} 
                                    sx={{
                                        borderRadius: 1, 
                                        border: 0,
                                    }}
                                />
                            </ConditionalLink>
                        </Box> 
                    }
                    {!guest 
                        && <Box sx={{
                            width: ['100%', '100%', 'auto'],
                        }}>
                            <SocialLinks data={props.data} uid={props.uid} />
                        </Box>
                    }
                </Flex>
            </div>

            <div sx={{
                pt: 2,
                px: 4,
                pb: 3,
            }}>
                <ConditionalLink
                    sx={{
                        textDecoration: 'none',
                        color: 'text',
                    }}
                    className="card"
                    data-card={uid}
                    data-uid={uid}
                    data-verified={verified ? "true" : "false"}
                    data-enhanced={enhancedprofile ? "true" : "false"}
                >
                    {guest && 
                        <div
                            sx={{
                                fontSize: "13px", lineHeight: '1.2',
                                // mt: '-10px', 
                                mb: 3,
                                a: {
                                    color: "primary"
                                }
                            }}
                        >
                            Cet établissement vous appartient ? <Link to={"/referencement"}>Contactez-nous</Link> pour authentifier et gérer ce listing.
                        </div>}
                    {locations && 
                        <h2
                            sx={{
                                fontSize: 1, color: 'grey',
                                fontFamily: 'body', fontWeight: 'bold',
                                mt: 0, mb: 0,
                            }}
                        >
                            <MdPlace sx={{mb: '-2px'}} /> {prettyCategory ? `${prettyCategory[0]}` : null} {locations && ` à ${locations}`}
                        </h2>
                    }
                    <h3 sx={{
                        mt: 1, mb: 0, 
                        fontSize: 4, color: 'text',
                        fontFamily: 'heading', fontWeight: 'body',
                        ...(guest ? {} : {
                            '&:hover': {
                                color: 'primary',
                            }
                        })
                    }} >
                        {name} {verified && <span sx={{fontSize: 1, color: 'limegreen', fontFamily: 'body',}}><MdVerifiedUser sx={{mb: '-2px'}} /> Profil vérifié</span>}
                    </h3>

                    {tags &&
                        <Tags tags={tags} />
                    }

                    <p sx={{
                        mt: 1,
                        fontSize: 1, color: 'grey',
                        fontFamily: 'body', fontWeight: 'body',
                    }} >
                        {pitch}
                    </p>
                </ConditionalLink>

                {addLinks && <div>
                    <div sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        'a': {
                            marginRight: "0",
                        }
                    }}>
                        <Button link={`/${uid}`} className="card-button" data-button="page--internal" data-uid={uid} color={`limegreen`}>
                                <FaInfoCircle /> Voir la Fiche
                        </Button>

                        {!enhancedprofile && url && 
                            <Button link={url} 
                                title={`Aller sur le site internet de ${name}`} 
                                className="card-button" 
                                data-button="page--external-url" 
                                data-uid={uid} blank="true">
                                <FaExternalLinkSquareAlt /> Voir Site Web
                            </Button>}

                        {!enhancedprofile && telephone && 
                            <Button link={`tel:${telephone}`} title={`Appeler par téléphone ${name}`} className="card-button" data-uid={uid} data-button="contact--phone">
                                <FaPhone /> {telephone.substr(0, 8)}...
                            </Button>}

                        {!enhancedprofile && email && 
                            <Button link={`mailto:${email}`} title={`Ecrire un email à ${name}`} className="card-button" data-uid={uid} data-button="contact--email">
                                <MdEmail /> Contacter par email
                            </Button>}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Listing 